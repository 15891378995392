import React, { useState, useLayoutEffect } from 'react';
import "../CSS/HomePages.css";
import Footer from "../Component/Footer";
import $ from "jquery";
///////////////////////// carousel-material ////////////////////////////
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from 'react-router-dom';


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

// Banner Images 

import HomeBanner01 from "../Images/Banner Images/Home-page-banner01.jpg";

// Home Section 2 

import WorkerComp from "../Images/Worker Comp.jpg";
import SurgeryCenter from "../Images/Surgery Center.jpg";
import PhysicalTherapy from "../Images/Physical Therapy.jpg";
import WelcomeSectionImage from "../Images/Welcome Section.jpg";

import icon01 from "../Images/Banner Images/medical-team.png";
import notepad from "../Images/Banner Images/notebook.png";
import SIntrument from "../Images/Banner Images/surgery.png"
import Hospital from "../Images/Banner Images/hospital.png";

import DoctorI1 from "../Images/Doctor Images/Archana Patil.jpeg";
import DoctorI2 from "../Images/Doctor Images/Chetan Patil.jpeg";
import DoctorI3 from "../Images/Doctor Images/Pallavi Tari.jpeg";


////////////////////////////// Our Services /////////////////////////////
import DepartmentData from "../Component/DepartmentData";

///////////////////////////// Main Services //////////////////////////////

import AllMediclaimFacilityAvailable from "../Images/Main Services/medical-history.png";
import NabhAccredited from "../Images/Main Services/NABH.png";
import UltrasoundSonographyCentres from "../Images/Main Services/sonography.png";
import Pharmacy from "../Images/Main Services/pharmacy.png";
import Pathology from "../Images/Main Services/biologist.png";
import AmbulanceServices from "../Images/Main Services/ambulance.png";
import BloodStorageCenter from "../Images/Main Services/blood-bank.png";
import TestimonialData from '../Component/TestimonialData';

const HomePage = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [custName, setcustName] = useState("");
    const [custContact, setcustContact] = useState("");
    const [appointmentDate, setAppointmentDate] = useState("");
    const [custServices, setCustService] = useState("");

    const OnSubmit = (e) => {
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#8e1c76">Shree Krishna Hospital Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + custName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact:</strong></td><td style="text-align:left">' + custContact + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Date:</strong></td><td style="text-align:left">' + appointmentDate + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Service:</strong></td><td style="text-align:left">' + custServices + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Shree Krishna Hospital</p></div></body></html>';

        console.log(custServices)

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {

            toEmail: "shreekrishnahospital.co@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "ShreeKrishnaHospital",
            accountLeadSource: "shrikrishnahospital.in",
            checkin: appointmentDate,
            accountLeadName: custName,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + custName);
                console.log("name:" + appointmentDate);


            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        e.preventDefault()

        return false;

    }


    return (
        <>
            <section className='carousel-section'>
                <div className='Swiper'>

                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='HomeBanner01'>
                                <div className='Home-page-banner-Text-con'>
                                    <h1>Your Health, Our Expertise: Where Every Specialization Meets Compassionate Care</h1>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='HomeBanner02'>
                                <div className='Home-page-banner-Text-con'>
                                    <h1>Empowering Health through Comprehensive Expertise: Your Wellness, Our Priority.</h1>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='HomeBanner03'>
                                <div className='Home-page-banner-Text-con'>
                                    <h1>Healing Pioneers Across Specializations: Welcome to Our Multispecialty Hospital</h1>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>


            {/* /////////////////////////////////// our-Services ///////////////////////////////////// */}

            <section className='our-Services'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='card-1'>
                                <h2>Our Department</h2>
                                <hr></hr>
                                <h4>modern equipment and specialist</h4>
                                {/* <a href="#"><span>MORE</span></a> */}
                            </div>
                        </div>
                        {
                            DepartmentData.map((ele) => {
                                const { DepartmentIcon, DepartmentName, DepartmentSortDes } = ele
                                return (
                                    <>
                                        <div className='col-lg-4'>
                                            <div className='card-2'>
                                                <div className='hospital-logo'>
                                                    {DepartmentIcon}
                                                </div>
                                                <div className='card-2-material'>
                                                    <h3>{DepartmentName}</h3>
                                                    <p>{DepartmentSortDes}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>

            {/* ////////////////////////// Section 3 /////////////////////////////// */}

            <section className='homeSection3'>
                <div className='container'>
                    <div className='HomeSection2-Welcome'>
                        <div className='row team-row'>
                            <div className='col-md-6 col-sm-12 order-md-1 order-2'>
                                <div className='WelcomeSectionHome-Details'>
                                    <div className='WelcomeSectionHome-Info'>
                                        <h5>Welcome to Our Shree Krishna Hospital</h5>
                                        <h2>Know Our Gynaecologist </h2>
                                        <p>Committed to optimal health for each patient, our doctors and our talented care team are dedicated to ensuring each patient returns to their preferred activities.our doctors are dedicated to provide compassionate care to our patients. Moreover, the various knowledge and skills of all of our surgeons enable us to provide comprehensive orthopedics care and services.</p>
                                        <Link to="/AboutUs">
                                            <button className='morebtn'>
                                                more about us
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 order-md-2 order-1'>
                                <div className='WelcomeSectionHome-Img-con'>
                                    <div className='WelcomeSectionHome-Image'>
                                        <img src={DoctorI2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr></hr>


            {/* /////////////////////////////////  Add-Section /////////////////////////////////// */}


            <section className='Add-Section'>
                <div className='container'>
                    <div className='Add-Section-con'>
                        <div className='Add-Section-Head'>
                            <h2>
                                Trusted and reliable hospital is now expanding
                            </h2>
                        </div>
                        <div className='Add-Section-Row-Col'>
                            <div className='row row-cols-lg-4 row-cols-md-3'>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={AllMediclaimFacilityAvailable} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                all mediclaim facility available
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={NabhAccredited} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                Nabh Accredited
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={UltrasoundSonographyCentres} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                Ultra sound Sonography Centres
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={Pharmacy} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                Pharmacy (24/7)
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={Pathology} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                Pathology (24/7)
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={AmbulanceServices} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                Ambulance Services (24/7)
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='col'>
                                    <div className='Add-Section-Card-Body'>
                                        <div className='Add_Section-Icon-con'>
                                            <div className='Add_Section-Icon'>
                                                <img src={BloodStorageCenter} />
                                            </div>
                                        </div>
                                        <div className='Add_Section-Text-con'>
                                            <h6>
                                                Blood Storage
                                                1Center
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ////////////////////////// Section 4 /////////////////////////////// */}

            <section className='HomeSection4'>
                <div className='container'>
                    <div className='HomeSection4-Specialties'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='HS4-Specialties-text'>
                                    <div className='HS4-Specialties-info'>
                                        <h2>Tell us where it hurts</h2>
                                        <p>Specialized Orthopedics to Meet Your Needs</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                {/* <div className='HS4-Specialties-button-con'>
                                    <div className='HS4-Specialties-button'>
                                       
                                            <Link>
                                                <button>
                                                    View All Specialties
                                                </button>
                                            </Link>
                                       
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Specialties-Card-Container'>
                    <div className='row row-cols-lg-5 row-cols-md-5 row-cols-sm-2 row-cols-1'>

                        <div className='col' style={{ margin: "0px", padding: "0px" }}>
                            <div className='Card-Body'>
                                <div class="page">
                                    <div class="wrapper">
                                        <div class="front foot_pain">
                                            <p>Maternity care </p>
                                        </div>
                                        <div class="back foot_pain_Back">
                                            <p>Maternity care </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col' style={{ margin: "0px", padding: "0px" }}>
                            <div className='Card-Body'>
                                <div class="page">
                                    <div class="wrapper">
                                        <div class="front Elbowpain">
                                            <p>Paediatric care</p>
                                        </div>
                                        <div class="back ElbowpainBack">
                                            <p>Paediatric care</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col' style={{ margin: "0px", padding: "0px" }}>
                            <div className='Card-Body'>
                                <div class="page">
                                    <div class="wrapper">
                                        <div class="front wristpain">
                                            <p>Laproscopic surgery</p>
                                        </div>
                                        <div class="back wristpainBack">
                                            <p>Laproscopic surgery</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col' style={{ margin: "0px", padding: "0px" }}>
                            <div className='Card-Body'>
                                <div class="page">
                                    <div class="wrapper">
                                        <div class="front hippain">
                                            <p>Ultrasound</p>
                                        </div>
                                        <div class="back hippainBack">
                                            <p>Ultrasound</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col' style={{ margin: "0px", padding: "0px" }}>
                            <div className='Card-Body'>
                                <div class="page">
                                    <div class="wrapper">
                                        <div class="front kneepain">
                                            <p>Internal medicine</p>
                                        </div>
                                        <div class="back kneepainBack">
                                            <p>Internal medicine</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ////////////////////////// Section 5 /////////////////////////////// */}

            {/* ///////////////////////////////////// Section 3 //////////////////////////////////////////// */}

            <section className='HomeSection3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='HA-text'>
                                <h4>REQUEST FOR YOUR</h4>
                                <h2>Consultation</h2>

                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='HA-from'>

                                <form>
                                    <label>
                                        <input type="text" name="name" placeholder='Name' onChange={(e) => setcustName(e.target.value)} />
                                    </label>
                                    <label>
                                        <input type="number" name="Contact" placeholder='Contact' onChange={(e) => setcustContact(e.target.value)} />
                                    </label>
                                    <label>
                                        <input type="date" name="Date" placeholder='Date' style={{ color: "black" }} onChange={(e) => setAppointmentDate(e.target.value)} />
                                    </label>
                                    <label>

                                        <select value={custServices} onChange={(e) => setCustService(e.target.value)}>
                                            <option selected value="">Type of Service</option>
                                            <option value="ICU & NICU">ICU & NICU</option>
                                            <option value="Accident & Trauma">Accident & Trauma</option>
                                            <option value="Internal Medicine">Internal Medicine</option>
                                            <option value="Paediatric Surgery">Paediatric Surgery</option>
                                            <option value="Gynaecology & Maternity Care">Gynaecology & Maternity Care</option>
                                            <option value="Anc Check Up">Anc Check Up</option>
                                            <option value="Nephrology Clinic">Nephrology Clinic</option>
                                            <option value="Diabetic & Hypertension Clinic">Diabetic & Hypertension Clinic</option>
                                            <option value="General Surgery">General Surgery</option>
                                            <option value="Dermatology Skin Clinic">Dermatology Skin Clinic</option>
                                            <option value="Neurology Clinic">Neurology Clinic</option>
                                            <option value="Urology Surgery">Urology Surgery</option>
                                            <option value="Ortho Surgery">Ortho Surgery</option>
                                            <option value="Ent Surgery">Ent Surgery</option>
                                            <option value="Paediatric Clinic">Paediatric Clinic</option>
                                            <option value="Neurosurgery">Neurosurgery</option>
                                            <option value="Physiotherapy">Physiotherapy</option>
                                            <option value="Dietician">Dietician</option>
                                        </select>
                                    </label>
                                    <input type="submit" value="Submit" className='btn' onClick={OnSubmit} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ////////////////////////////// Section 6  //////////////////////////////// */}

            <section className='HomeSection6'>
                <div className='container'>
                    <div className='HomeSection6-WhyUS'>
                        <div className='HomeSection6-WhyUS-details'>
                            <h5>Our Advantages & Capabilities</h5>
                            <h2>Why patients choose us</h2>
                            <div className='HomeSection6-WhyUS-para'>
                                <p>Our best Multispecialty hospital in titwala make life healthier and longer. By implementing global diagnostic and therapeutic solutions, we aim to achieve and maintain the highest ranking in leading medical centers in India.</p>
                            </div>
                        </div>

                        <div className='HS6-Icons-Details'>
                            <div className='container'>
                                <div className='HS6-Icons-Details-con'>
                                    <div className='row row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 '>

                                        <div className='cols'>
                                            <div className='mainiconimage'>
                                                <div className='HS6-Icon-con'>
                                                    <div className='HS6-Icon'>
                                                        <img src={icon01} alt="" />
                                                    </div>

                                                </div>
                                                <div className='HS6-Icon-info'>
                                                    <h4>Find A Physician</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='cols'>
                                            <div className='mainiconimage'>
                                                <div className='HS6-Icon-con'>

                                                    <div className='HS6-Icon'>
                                                        <img src={notepad} alt="" />
                                                    </div>

                                                </div>
                                                <div className='HS6-Icon-info'>

                                                    <h4>request and appointment</h4>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='cols'>
                                            <div className='mainiconimage'>
                                                <div className='HS6-Icon-con'>

                                                    <div className='HS6-Icon'>
                                                        <img src={SIntrument} alt="" />
                                                    </div>

                                                </div>
                                                <div className='HS6-Icon-info'>

                                                    <h4>prepare of surgery</h4>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='cols'>
                                            <div className='mainiconimage'>
                                                <div className='HS6-Icon-con'>

                                                    <div className='HS6-Icon'>
                                                        <img src={Hospital} alt="" />
                                                    </div>

                                                </div>
                                                <div className='HS6-Icon-info'>

                                                    <h4>see our location</h4>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* ////////////////////////// Section 7 ////////////////////////////// */}


            <section className='HomeSection7'>
                <div className='HomeSection7-Doctor'>
                    <div className='container'>
                        <div className='HomeSection7-team'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='HS4-Team-text'>
                                        <div className='HS4-Team-info'>

                                            <h2>Our Professional Team</h2>

                                            <p>Meet the doctors</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                </div>
                            </div>
                        </div>
                        <div className='HomeSection7-team-con'>
                            <div className='row'>
                                <div className='col-md-4' style={{ padding: "0px", margin: "0px" }}>
                                    <div className='Team-Card-Body'>

                                        <div className='Team-Card-Image'>
                                            <img src={DoctorI2} alt="" />
                                        </div>

                                        <div className='Team-Crad-Info-con'>
                                            <div className='Team-Card-Info'>
                                                <h6>Gynaecologist & Maternity care </h6>
                                                <h2>Dr. Chetan Patil</h2>
                                                <p>M.B.B.S., DGO, DNB(MUM)</p>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-4 res-big-doctor' style={{ padding: "0px", margin: "0px" }}>
                                    <div className='Team-Card-Body'>
                                        <div className='Team-Crad-Info-con'>
                                            <div className='Team-Card-Info'>
                                                <h6>Paediatric</h6>
                                                <h2>Dr. Pallavi Tari</h2>
                                                <p>M.B.B.S., DCH, PGPN</p>
                                            </div>
                                        </div>

                                        <div className='Team-Card-Image'>
                                            <img src={DoctorI3} alt="" />
                                        </div>

                                    </div>
                                </div>

                                <div className='col-md-4' style={{ padding: "0px", margin: "0px" }}>
                                    <div className='Team-Card-Body'>

                                        <div className='Team-Card-Image'>
                                            <img src={DoctorI1} alt="" />
                                        </div>

                                        <div className='Team-Crad-Info-con'>
                                            <div className='Team-Card-Info'>
                                                <h6>Dermatologist</h6>
                                                <h2>Dr. Archana Patil</h2>
                                                <p>M.B.B.S., DNB, Skin (Mum)</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ///////////////////////////////////// Section 8 //////////////////////////////////////////// */}

            <section className='HomeSection8'>
                <div className='HomeSection8-Review'>
                    <div className='HomeSection8-Review-Data-con'>
                        <div className='HomeSection8-Review-Data'>
                            <h5>Testimonials</h5>
                            <h2>Our Clients Say</h2>
                        </div>
                        <div className='HomeSection8-Review-Swiper'>
                            <Swiper
                                spaceBetween={40}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 50000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                {
                                    TestimonialData.map((elem) => {
                                        return (
                                            <>
                                                <SwiperSlide>
                                                    <div className='HomeSection8-Review-Swiper-Data'>
                                                        <p>
                                                            {elem.Discription}                                                        </p>
                                                        <h4>{elem.Name}</h4>
                                                    </div>
                                                </SwiperSlide>
                                            </>
                                        )
                                    })
                                }
                                <SwiperSlide>
                                    <div className='HomeSection8-Review-Swiper-Data'>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur expedita distinctio velit quibusdam adipisci ab, illo pariatur, dolorem perferendis cum amet repellendus, facere id sapiente dignissimos. Dolores necessitatibus culpa saepe?
                                        </p>
                                        <h4>kashish jain</h4>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='HomeSection8-Review-Swiper-Data'>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur expedita distinctio velit quibusdam adipisci ab, illo pariatur, dolorem perferendis cum amet repellendus, facere id sapiente dignissimos. Dolores necessitatibus culpa saepe?
                                        </p>
                                        <h4>Dania Nanda</h4>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='HomeSection8-Review-Swiper-Data'>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur expedita distinctio velit quibusdam adipisci ab, illo pariatur, dolorem perferendis cum amet repellendus, facere id sapiente dignissimos. Dolores necessitatibus culpa saepe?
                                        </p>
                                        <h4>Dilip Sharma</h4>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='HomeSection8-Review-Swiper-Data'>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur expedita distinctio velit quibusdam adipisci ab, illo pariatur, dolorem perferendis cum amet repellendus, facere id sapiente dignissimos. Dolores necessitatibus culpa saepe?
                                        </p>
                                        <h4>Tejasvee Kotian</h4>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default HomePage;