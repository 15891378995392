import PaediatricSurgery from "../Images/Specialities/Paediatric Surgery.jpg";
import AncCheckUp from "../Images/Specialities/Anc Check Up.jpg";
import EntSurgery from "../Images/Specialities/Ent Surgery.jpeg";
import UrologySurgery from "../Images/Specialities/Urology Surgery.jpeg";
import InternalMedicine from "../Images/Specialities/Internal Medicine.jpg";
import GeneralSurgery from "../Images/Specialities/General Surgery.jpg";
import Dietician from "../Images/Specialities/Dietician.jpg";
import OrthoSurgery from "../Images/Specialities/Ortho Surgery.jpg";
import AccidentTruma from "../Images/Specialities/Accident & Truma.jpg";
import DiabetologyCardiology from "../Images/Services Images/Diabetology & Cardiology.jpeg";
import DepartmentOfChestMedicine from "../Images/Services Images/Department Of Chest Medicine.jpg";
import OncologyDepartment from "../Images/Services Images/Oncology Department.jpg";
import DepartmentOfRadiology from "../Images/Services Images/Department Of Radiology.jpg";
import DepartmentOfPathology from "../Images/Services Images/Department Of Pathology.jpg";
import DepartmentOfDermatology from "../Images/Services Images/Department Of Dermatology.jpg";
import OpthalmologyDepartment from "../Images/Services Images/Opthalmology Department.jpg";
import PhysiotherapyAndRehabilitation from "../Images/Services Images/Physiotherapy And Rehabilitation.jpg";
import DentalDepartment from "../Images/Services Images/Dental Department.jpg";

const Servicesdrop = [
    {
        id: 1,
        Slugs:"/paediatrics-depatment",
        Servicesdropname:"Paediatrics Depatment",
        ServicesImage:PaediatricSurgery,
        HeadDes01: "Pediatric Surgery Hospital in titwala, provides general and specialty surgical services to infants, children and adolescents with a variety of congenital and acquired diseases such as hernias, edema, cryptorchidism, cysts and tumors of the head and neck. ",
        HeadDes02: "Our team listens to you and your child. We will work with you to come up with a treatment plan that is tailored to your child's condition. We are constantly engaged in clinical research to develop new diagnostic and therapeutic strategies.",
        HeadDes03: "Paediatric surgery Hospital in titwala strives to provide first-class clinical care for children, both outpatient and inpatient. Care is designed to help children recover from mundane to highly complex illnesses that occur from conception through adulthood.",
        HeadDes04: "Our pediatric surgeons and care team work in a learning environment that values children. Working with the Pediatric Surgical Hospital in Titwara and highly qualified pediatric anesthesiologists, these teams approach every procedure with special care for pediatric patients. ",
        HeadDes05: " We are committed to continuously educating our patients, families and colleagues, as well as expanding the knowledge of future leaders in our field.",
        HeadDes06: " We support both the basic and clinical exploration of new diagnostic and therapeutic strategies and the joy and fulfillment of discovery itself. We aim to be a team of teachers who are full of their own growth, grateful and rewarded for their health and families. We give you the opportunity to take care of your children and your family.",
        HeadDes07: " At  Pediatric Surgery Hospital in titwala , our team provides care and treatment for infants, children and adolescents with a wide range of common and complex conditions. In our hospital, we introduce various surgical methods including the latest minimally invasive surgery for patients who need surgery. Our team creates an individual care plan for each patient's condition and unique circumstances to ensure the best possible treatment and recovery options.",
    },
    {
        id: 2,
        Slugs:"/gynaecology-obstetrics",
        Servicesdropname:"Gynaecology & Obstetrics",
        ServicesImage:AncCheckUp,
        HeadDes01: "At the Gynecology and Maternity Care Hospital in Mumbai, we've designed family-centered maternal and child health services focused on you, your family, and your newborn. Our facilities and the services we offer can help make your care easier, from prenatal testing and birth assistance to postnatal and neonatal care.",
        HeadDes02: "Our maternal and child health team consists of highly qualified and board-certified obstetricians, neonatologists, and nurses who provide the warm, caring care of a community hospital. It offers.",
        HeadDes03: "When choosing a hospital to deliver a newborn, expectant parents understand that quality care is the most important consideration and seek a hospital that provides the safest environment for the delivery of their newborn.",
        HeadDes04: "Gynecology and maternity care hospital in Mumbai delivers more babies than any other hospital in the county. At a gynecology and maternity care hospital in Mumbai, maternal and child health services are designed with clinical quality in mind. The birth of a baby is a very precious and important moment in a family's life. Even if your due date is far away, you'll be amazed at how quickly time flies.",
        HeadDes05: "We help women and babies achieve optimal health and empower women to have a strong and rewarding childbirth experience. We provide evidence-based care in a safe and welcoming atmosphere, grounded in cultural humility and caregiver diversity. Our care is innovative, clinically superior, and complements a growing body of research on best practices for women and families. Together, we educate and work to develop a new generation of midwives trained to support physiological childbirth and women-centred care. Our practices reflect our values of excellence, compassion, community, and social justice.",
    },
    {
        id: 3,
        Slugs:"/ent",
        Servicesdropname:"ENT Care",
        ServicesImage:EntSurgery,
        HeadDes01:"At the ENT surgery Hospital in Titwala, Our multidisciplinary team of board-certified otolaryngologists assesses, diagnoses, and treats all areas of ear, nose, and throat disease and offers advanced head and throat treatments. specialises in Committed to providing the highest quality, the doctors at Thitwara Ear, Nose, and Throat Hospital are highly qualified to provide the care you need and state-of-the-art treatments, treating each condition individually.",
        HeadDes02:"Otolaryngology surgery is performed on the head or neck to treat ear, nose, and throat problems. Also called otolaryngological (ENT) surgery. An otolaryngologist is a doctor trained to treat patients with diseases and disorders of the ear, nose, and throat. In some cases, these disorders may require surgery. Learn more about ENT surgery. Otorhinolaryngology focuses on the surgical treatment of diseases of the ear, nose, and throat. This type of surgery is performed by an otolaryngologist, a doctor trained to treat patients with disorders and diseases of the ear, nose, throat, and other structures of the throat and face.",
        HeadDes03:"Ear, nose, and throat surgery helps repair damage or defects in these areas that cause problems such as pain, frequent infections, and trouble breathing. For example, some children need ENT surgery to treat recurring ear infections. Ear, nose, and throat surgery may be needed to remove the tumour (abnormal growth). Depending on the procedure, surgery can be done in a clinic, outpatient facility, or hospital.",
        PointHead01:"Some of the most common ENT disorders that may require ENT surgery include:",
        Point01:"ear problems",
        Point02:"frequent ear infections",
        Point03:"nose problems",
        Point04:"frequent sinus pains and infections.",
        Point05:"throat problems",
        Point06:"vocal cord disorders",
        Point07:"tonsillitis (inflammation of the tonsils, the two tissue pads in the back of the throat)",
        Point08:"difficulty swallowing",
        PointHead001:"Other head and neck problems:",
        Point001:"head or neck cancer",
        Point002:"face damage",
    },
    {
        id: 4,
        Slugs:"/urology-department",
        Servicesdropname:"Urology Department",
        ServicesImage:UrologySurgery,
        HeadDes01:"At the urology surgery hospital in titwala,  our experienced team provides specialized urology care in a comfortable and welcoming environment. We offer both surgical and non-surgical treatments for a variety of conditions affecting the colon, prostate and bladder.",
        Ques01:"What is Urology?",
        Ans001:"Urology is one of the most diverse branches of surgery and includes diseases of the kidneys, bladder and prostate, including incontinence, impotence, infertility, cancer and reconstruction of the urogenital tract. A wide range of patients come to the hospital regardless of gender and age, from newborns to older adults.",
        Ans002:" Talking about urological problems can be difficult. Our team at Titwala Urological Surgical Hospital strives to ensure your comfort so that we can provide you with the best care you need. From general urological health to more specific erectile dysfunction solutions, we are committed to improving the quality of life for our patients through innovative treatments.",
        PointHead01:"We treat the following diseases.",
        Point01:"Incontinence",
        Point02:"Impotence or erectile dysfunction",
        Point03:"Kidney stones",
        Point04:"Renal disease",
        Point05:"Kidney disease",
        Point06:"Prostate cancer",
        Point07:"Bladder cancer",
        Point08:"Testicular cancer",
        Point09:"Pelvic floor dysfunction",
        EndDes01:"Our team of urologists use the latest surgical techniques to treat a wide variety of urological diseases. Urology Surgery Hospital in titwala offers minimally invasive and laparoscopic surgery to help patients recover faster and with less pain.",
    },
    {
        id: 5,
        Slugs:"/internal-medicine",
        Servicesdropname:"Internal Medicine",
        ServicesImage:InternalMedicine,
        HeadDes01: "If you are looking for information about internists and the specialties of internal medicine, you are in the right place. Internal medicine is a branch of medicine that focuses on the prevention, diagnosis, and treatment of various diseases in adults.",
        HeadDes02: "Our doctors specialize in the prevention, diagnosis, and treatment of diseases that primarily affect adults. As your primary care physician, we are here to be your trusted healthcare partner and to build a long-term relationship with you throughout adulthood. ",
        HeadDes03: "We specialize in adult medicine and are specially trained to solve diagnostic problems, manage serious long-term illnesses, and assist patients with multiple complex chronic conditions.",
        Ques01:"What is internal medicine? ",
        Ans001:"Physicians are professionals who apply scientific knowledge and clinical expertise to the diagnosis, treatment, and compassionate care of adults, from wellness to complex illnesses. Physicians in internal medicine, also known as internists, are experts in complexity. They specialize in adult medicine and are specially trained to solve diagnostic problems, treat serious long-term illnesses, and care for patients with multiple complex chronic conditions. They provide comprehensive and long-term patient care. Furthermore, they develop lifelong relationships with their adult patients, and their recommendations are based on each patient's unique circumstances. ",
        Ans002:"An expert in internal medicine explains. Their deep training and knowledge of the entire human body and its organ systems give them a unique perspective on how everything works together. They analyze, explore, and connect multiple datasets to find solutions for optimal health outcomes. Physicians of internal medicine are called 'physicians' because they are often referred to by other medical professionals because of their ability to make connections and solve problems. Our expertise has made our indispensable to both patients and healthcare professionals.",
    },
    {
        id: 6,
        Slugs:"/diabetology-cardiology",
        Servicesdropname:"Diabetology & Cardiology",
        ServicesImage:DiabetologyCardiology,
        HeadDes01:"The Diabetes and Hypertension Clinic in Titwala provides the best evidence-based diabetes, hypertension, and endocrine care in a stimulating environment to put patients at ease. People with diabetes are twice as likely to develop high blood pressure as people without diabetes. Left untreated, high blood pressure can lead to heart disease and stroke. In fact, a person with diabetes and high blood pressure is four times more likely to develop heart disease than someone without either condition. About two-thirds of adults with diabetes have a blood pressure of 130/80 mmHg or higher, or take prescription medications for high blood pressure.",
        PointHead01:"Diabetes",
        Point01:"We know a lot about diabetes. It is now a household name. This chronic disease is caused by a congenital or acquired deficiency of insulin production and usually manifests as hyperglycemia, affecting nerves, blood vessels, and many other body systems.",
        Point02:"And with more than 77 million Indians diagnosed with diabetes, effective care and treatment are an urgent need. Under our team, Shree Krishna Hospital offers a unique and personalized treatment plan to help patients manage diabetes and its complications with ease.",
        PointHead001:"Hypertension",
        Point001:"High blood pressure, also called hypertension, has many complications but rarely causes symptoms. Left unchecked, it can damage your internal organs and cardiovascular system, increasing your risk of heart disease, stroke, and death.",
        Point002:"Treating high blood pressure is even more important for a diabetic because he is twice as likely to develop it. Additionally, a person diagnosed with both diabetes and high blood pressure is four times more likely to develop heart disease than he is.",
        Point003:" To manage these risks and provide a better quality of life, our long-term diabetes and hypertension management plan includes",
        Point004:"Strategic Use of Insulin and Diabetes Medications",
        Point005:"Introduction of Lifestyle Changes Through Diet and Physical Activity",
        Point006:"Containment of Unsuitable Habits, Such as Drinking and Smoking",
        Point007:"Addressing other diseases and side effects Caused by Diabetes ",
    },
    {
        id: 8,
        Slugs:"/department-of-diet-nutrition",
        Servicesdropname:"Department Of Diet & Nutrition",
        ServicesImage:Dietician,
        HeadDes01:"The Department of Nutrition and Dietetics at Shree Krishna Hospital continues to serve patients with an ever-growing staff based on evidence-based applications while strictly respecting patient wishes. Nutrition and nutrition clinics carry out 'individualised dietary programmes' with health-friendly and sometimes therapeutic elements. In addition to nutritional treatment for inpatients, we also plan menus prescribed by doctors and nutritionists for inpatients at all facilities.",
        HeadDes02:"The goal of the dietitian clinic in Titwala is to educate patients about healthy eating and encourage behavioural change in patients hospitalised with health problems. To this end, we discuss each patient's diet in detail during the initial consultation. For all patients requiring nutritional guidance, body fat, muscle, and water percentages are measured to determine fat distribution. An individualised nutrition plan is then created, taking into account each patient's age, height, gender, and unique physiology. Blood values and patient compliance with the individual plan are monitored regularly. In addition, nutritional plans are developed and documented for patients with certain diseases, such as diabetes, and natural physical changes, such as pregnancy, breastfeeding, childhood, and adolescence.",
        HeadDes03:"There is one key feature of the dietitian clinic in Titwala that allows Shree Hospital to stay ahead of the competition. Our hospital screens all inpatients for malnutrition (a clinical condition associated with inadequate nutrient content and quantity and a lack of energy and nutrients despite body needs), which is making a big positive difference.",
        HeadDes04:"Nutrition and dietary advice and special menu planning are available upon request from private agencies and organisations. Additionally, our nutritionists frequently appear in written and video media to continue to educate and raise awareness among the public.",
    },
    {
        id: 10,
        Slugs:"/oncology-department",
        Servicesdropname:"Oncology Department",
        ServicesImage:OncologyDepartment,
        HeadDes01:"The Oncology Department is led by our No. 1 oncology doctor in Titwala, who employs a multidisciplinary approach to ensure patients receive the best possible care and goes all out. Our oncologists work with specially trained consultants, nutritionists, speech therapists, and other professionals to determine the most appropriate treatment based on the requirements of each individual case. increase.",
        HeadDes02:"The Department of Oncology has two main specialties, Surgical Oncology and Medical Oncology, with specialists treating early and advanced-stage malignancies of muscles, organs, connective tissue, and bone. They pursue a comprehensive and holistic approach to patient care, from early detection and diagnosis to chemotherapy, drug therapy, and surgery. In our mission to win the fight against cancer, oncologists are helping to redefine medical standards and outcomes for every patient they see.",
        HeadDes03:"The Oncology Clinic is an outpatient programme that provides comprehensive care to people with cancer and blood disorders. We understand that being diagnosed with cancer can be scary and complicated to treat. By working together, we can bring quality cancer care closer to where patients live. We also provide various infusions and injections for symptoms other than cancer. Our goal is to give patients the confidence they need to manage their illness and treatment options through personalised and compassionate care so they can enjoy a high quality of life. The Oncology Clinic offers the following services:",
        Point01:"Individual Case Management and Care ",
        Point02:"Certified Oncology Nurse",
        Point03:"Chemotherapy (Cancer Treatment)",
        Point04:"Anaemia Treatment ",
        Point05:"Blood Transfusion ",
        Point06:"Phlebotomy",
    },
    {
        id: 11,
        Slugs:"/department-of-radiology",
        Servicesdropname:"Department Of Radiology",
        ServicesImage:DepartmentOfRadiology,
        HeadDes01:"In the radiology clinic in Titwala, Our radiology team consists of paediatric radiologists, technicians, nurses, child life specialists, and support staff specially trained in paediatric care. Common radiological procedures include:",
        Point01:"Plain films (also called X-rays and fluoroscopy)",
        Point02:"CT and MR (also called CAT and MRI scans)",
        Point03:"Ultrasound",
        Point04:"Nuclear medicine and bone density scans",
    },
    {
        id: 12,
        Slugs:"/department-of-pathology",
        Servicesdropname:"Department Of Pathology",
        ServicesImage:DepartmentOfPathology,
        HeadDes01:"In our pathology lab in Titwala, Our pathology and laboratory team includes physicians and clinical staff specially trained in recognising and interpreting laboratory results for specific pediatric diseases. Our staff uses state-of-the-art testing equipment designed for small, pediatric-sized samples. Blood collection team members, also called phlebotomists, are specially trained to check the health of children.",
    },
    {
        id: 13,
        Slugs:"/department-of-dermatology",
        Servicesdropname:"Department Of Dermatology",
        ServicesImage:DepartmentOfDermatology,
        HeadDes01:"Those seeking answers to skin, mucous membrane, and nail ailments, hair loss, and facial aging can find the help they need at the Dermatology Clinic in titwala , a well-known dermatology clinic. Our dermatologists (adult and pediatric dermatologists, dermatopathologists, skin surgeons) diagnose and treat hundreds of conditions, including rare and genetic conditions. They work closely with you to understand your dermatological needs and find the right answer from the beginning.",
        HeadDes02:"All this expertise in one place means your treatment is discussed as a team, test results are readily available, and appointments are coordinated. What may take weeks or even months elsewhere can usually be completed in a few days at the Titwara Dermatology Clinic.",
        HeadDes03:"Our dermatologists, dermatopathologists and Mohs surgeons also provide resources to complement the treatment you receive from your local doctor.",
        HeadDes04:"Professional skin care for patients of all ages",
        HeadDes05:"An exceptional level of knowledge from professionally trained physicians and certified dermatologists. The focus of our practice is delicate and personal care. At our clinic, we strive to provide you with the latest dermatology treatments in a comfortable environment.",
        HeadDes06:"In the Dermatology clinic in titwala , Our dermatologists treat patients with all types of skin conditions, including moles and rashes, up to the diagnosis of skin cancer.",
        HeadDes07:"Our experienced board-certified dermatologists are able to treat the most complex conditions with a comprehensive, team-based approach",
    },
    {
        id: 15,
        Slugs:"/orthopedic-department",
        Servicesdropname:"Orthopedic Department",
        ServicesImage:OrthoSurgery,
        HeadDes01:"The orthosurgery department in Titwala strives to provide cutting-edge treatments and exceptional compassionate care for patients with orthopaedic and musculoskeletal disorders. We also offer non-surgical general musculoskeletal care for common orthopaedic conditions that are best treated without surgery. In conjunction with the Physiotherapy, rehabilitation, and Primary Care departments, our department has highly qualified non-surgical doctors who specialise in the non-surgical treatment of various diseases of the musculoskeletal system. Common orthopaedic conditions such as osteoarthritis, back pain, sciatica, carpal tunnel syndrome, rotator cuff shoulder pain, knee ligament and meniscus injuries, hallux valgus, tennis elbow, hip impingement syndrome, muscle and tendon injuries, and bone fractures are all common examples. Conservative treatment is successful in many cases of diagnosis, but in some cases, surgery may be needed to restore the quality of life the patient desires.",
        HeadDes02:"Our vision for our orthosurgery hospital in Titwala is to provide the local, national, and international communities with the highest quality orthopaedic care from the most experienced and respected academic orthopaedic surgeons. Our surgical team also works closely with other medical professionals and staff to provide optimal clinical care, thereby achieving the best possible clinical outcome for our patients.",
        HeadDes03:"The goal of orthopaedic surgery is to relieve pain and allow you to return to your favourite activities. However, not all hospitals have the same results. Some things are more reliable than others. The Hospital Reliability Scorecard helps you ask the right questions to find the right hospital for you. Understanding the following data points will help you make the best decision for treatment. Our surgeons continue to push the boundaries of what is possible in musculoskeletal care.",
    },
    {
        id: 17,
        Slugs:"/physiotherapy-and-rehabilitation",
        Servicesdropname:"Physiotherapy And Rehabilitation",
        ServicesImage:PhysiotherapyAndRehabilitation,
        HeadDes01: "Our healthcare system is under stress, and you or a loved one may not get the physical therapy they need to stay healthy and mobile while in the hospital. Physiotherapists work with people suffering from physical and medical disabilities and illnesses. Through expert care, they aim to improve physical activity, mobility, and overall quality of life.",
        HeadDes02: "In the physiotherapy clinic in Titwala, they are trained to perform comprehensive assessments, tests, and treatments to address each patient's specific diagnosis and concerns.",
        HeadDes03: "Physiotherapists work with patients to ensure treatment plans are tailored to each patient's unique circumstances. It also monitors patients progress towards treatment goals.",
        HeadDes04: "In the physiotherapy clinic in Titwala, our Physiotherapists help patients improve their mobility, strength, and balance through both individual and group therapy. We support people who want to live independently in the community despite their difficulties. Physiotherapy services for inpatients include assessment and treatment of appropriate physiotherapy during hospitalisation, provision of walking aids, splints, and orthotics, training of patients and carers to support home care, hospital-to-community Includes a discharge plan to facilitate transition into society.",
        PointHeadTitle01: "Our Services at the physiotherapy clinic in Titwala",
        PointHead01: "Our physiotherapists treat many conditions and ailments, such as:",
        Point01: "Respiratory disease due to a sudden serious infection",
        Point02: "Long-lasting respiratory diseases, such as chronic obstructive pulmonary disease (COPD)",
        Point03: "Impairment due to a sudden neurological event",
        PointHead001: "Stroke-resulting dysfunction Parkinson's disease",
        Point001: "Pain or disorder secondary to a sudden musculoskeletal or orthopaedic injury Sprains, Contusions, and fractures",
        Point002: "Hand injuries, surgical pain, and disabilities",
        Point003: "Long-term disorders affecting hands",
        Point004: "Women, such as incontinence",
        Point005: "Infant Developmental Delay/Musculoskeletal Disorders",
        Point006: "Fall Management",
        Point007: "Gait Retraining (retraining the patient to walk)",
    },
    {
        id: 18,
        Slugs:"/dental-department",
        Servicesdropname:"Dental Department",
        ServicesImage:DentalDepartment,
        HeadDes01:"Maintaining healthy gums and teeth is truly a lifelong commitment. Oral hygiene practises such as brushing and flossing can go a long way towards avoiding a doctor's visit, but sometimes you need a little extra care that only a professional can provide. Our dental clinic in Titwala helps people get dental care from an integrated team of highly qualified dentists and licenced specialists. Our doctors work seamlessly with a range of other specialists in our clinic to provide general dental care at Titwala and perform oral surgery procedures if required.",
        HeadDes02:"From dental cleanings to denture repairs to dental diagnosis and treatment, our patients can rest assured that they are receiving only the best care. At our clinic, our dentists conduct thorough examinations, formulate an appropriate treatment plan for each patient, and provide careful counselling. Complex dental problems such as sore teeth, loose teeth, hypersensitivity, bad breath, bleeding or swollen gums (pyorrhea), missing or missing teeth, discoloured teeth, broken jaws, abnormal teeth, and misaligned teeth A one-stop shop for all your dental needs Teeth, temporomandibular joint problems, broken teeth, dark gums, gaps between teeth, sore wisdom teeth, etc.",
        HeadDes03:"Shree Krishna Hospital offers the best dental care in a comfortable, modern, and relaxing environment with the latest technology and dental equipment.",
        HeadDes04:"Our dental clinic in Titwala has a dental area with state-of-the-art equipment, providing high-quality, clean, and comprehensive dental care at affordable prices. Modern dental care is revolutionised in terms of patient comfort (painless) operative time, minimal sessions, and particular attention to the highest standards of aseptic procedures to avoid cross-infection. Unlike traditional clinics, our clinic offers superior patient service, with half of our patients' fears disappearing upon arrival and the other half disappearing once they see the dentist.",
    },
];

export default Servicesdrop;
