import React, { useLayoutEffect } from 'react';
import "../CSS/Testimonial.css";
import TestimonialData from '../Component/TestimonialData';


import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Footer from '../Component/Footer';

const Testimonial = () => {

    const hrStyle = {
        margin: "30px 0px 30px 0px "
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

            <div className='Testimonial-banner'>
                <div className='Testimonial-banner-text'>
                    <h1>Testimonial</h1>
                </div>
            </div>

            {/* /////////////////////////////////////////// section 2 //////////////////////////////////////////// */}

            <section className='Testimonial-Section02'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {
                                TestimonialData.map((item, index) => {
                                    const { id, Name, Image, Discription } = item
                                    return (
                                        <>
                                            <div className='Main-Testimonial-Container'>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <div className='Testimonial-Image-Con'>
                                                            <div className='Testimonial-Images'>
                                                                <Zoom>
                                                                    <img src={Image} alt="" />
                                                                </Zoom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <div className='Testimonial-Info-Con'>
                                                            <div className='Testimonial-Name'>
                                                                <Zoom cascade>
                                                                    <h4>{Name}</h4>
                                                                </Zoom>
                                                            </div>
                                                            <div className='Testimonial-Description'>
                                                                <Zoom>
                                                                    <p>{Discription}</p>
                                                                </Zoom>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={hrStyle}></hr>
                                        </>
                                    )
                                })
                            }

                        </div>


                    </div>


                </div>


            </section>


            <Footer />
        </>
    )
}

export default Testimonial