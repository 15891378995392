
import "../CSS/ContactUs.css";

import React, { useState, useLayoutEffect } from 'react';
import $ from "jquery";
import { Link } from 'react-router-dom';
import Footer from "../Component/Footer"

///////

import Rotate from 'react-reveal/Rotate';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';



import { MdLocationPin } from 'react-icons/md';
import { HiMail } from 'react-icons/hi';
import { CgMediaPodcast } from 'react-icons/cg';
import { IoCall } from 'react-icons/io5';
import { AiFillInstagram } from 'react-icons/ai';
import { GrMail } from 'react-icons/gr';
import { FaPhoneSquareAlt } from 'react-icons/fa';
import { BsFacebook } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';

const ContactUs = () => {


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });


  const [custName, setcustName] = useState("");
  const [custEmail, setcustEmail] = useState("");
  const [custPhone, setcustPhone] = useState("");
  const [custMessage, setcustMessage] = useState("");

  const submitForm = (e) => {

    var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#8e1c76">Shree Krishna Hospital Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + custName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact:</strong></td><td style="text-align:left">' + custPhone + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email:</strong></td><td style="text-align:left">' + custEmail + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Shree Krishna Hospital</p></div></body></html>';

    $.post("https://skdm.in/server/v1/send_lead_mail.php", {

      toEmail: "shreekrishnahospital.co@gmail.com",
      fromEmail: "skdmlead@gmail.com",
      bccMail: "skdmlead@gmail.com",
      mailSubject: "New Lead genration",
      mailBody: body,
      accountName: "ShreeKrishnaHospital",
      accountLeadSource: "shrikrishnahospital.in",
      accountLeadName: custName,
      accountLeadPhone: custPhone,
      accountLeadEmail: custEmail,
      accountLeadMsgs: custMessage
    },

      function (dataa, status) {
        console.log('data :' + dataa);
        console.log("name:" + custName);

      });

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault()

    return false;
  }


  return (
    <>
      {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

      <div className='ContactUs-banner'>
        {/* <NavBarComponent /> */}
        <div className='ContactUs-banner-text'>
          <h1>Contact Us</h1>
        </div>
      </div>


      {/* ////////////////////////////////////// section 2 ////////////////////////////////// */}

      {/* ///////////////////////////////// section 2 /////////////////////////////////////////// */}


      <section className="Contact-Us-2">
        <div className='container'>
          <div className="row">
            <div className='row'>
              <div className='Office-Details'>
                <h3>
                  Office Details
                </h3>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <MdLocationPin />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>
                    <h2>Address</h2>
                    <Fade cascade>
                      <span>Shree Krishna multi-speciality hospital above<br></br> AXIS Bank , regency sarvam titwala east - 421605</span>
                    </Fade>

                  </div>
                </div>

              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <HiMail />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>

                    <h2>E-Mail</h2>
                    <Fade cascade>
                      <span><a href='mailto:shreekrishnahospital.co@gmail.com'>shreekrishnahospital.co@gmail.com</a></span>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <CgMediaPodcast />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>
                    <h2>Social Media</h2>
                    <Fade cascade>
                      <button className='Social-icon'><a href='https://www.instagram.com/shreekrishnahospital3/' target="_blank"><AiFillInstagram /></a></button>
                      <button className='Social-icon'><a href='https://www.facebook.com/profile.php?id=100069060434903' target="_blank"><BsFacebook /></a></button>
                      <button className='Social-icon'><a href='https://wa.me/9209199949?text=' target="_blank"><IoLogoWhatsapp /></a></button>
                      <button className='Social-icon'><a href='tel:+91 9209199949' target="_blank"><FaPhoneSquareAlt /></a></button>
                      <button className='Social-icon'><a href='mailto:shreekrishnahospital.co@gmail.com' target="_blank"><GrMail /></a></button>
                    </Fade>

                  </div>
                </div>

              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='Contact-info'>
                <div className='Contact-info-item'>
                  <div className='Contact-info-icon'>
                    <Zoom cascade>
                      <IoCall />
                    </Zoom>
                  </div>
                  <div className='Contact-info-text'>
                    <h2>Contact</h2>
                    <Fade cascade>
                      <span><a href='tel:+91 9209199949'>+91 92091 99949</a></span><br></br>
                    </Fade>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////// section 4 //////////////////////////////////////////////// */}

      <section className='ContactUs-form-Section-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-5 col-sm-12'>
              <div className='contact-form-image'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7531.233135518612!2d73.20706756576031!3d19.2990336561108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7911f9117e1ab%3A0x650e41530e6c9d9d!2sShree%20Krishna%20Hospital%20Titwala(E)!5e0!3m2!1sen!2sin!4v1692700761602!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
            <div className='col-md-7 col-sm-12'>
              <div className='Name-input'>
                <div className="row">
                  <div className="col">
                    <input type="text" className="form-control custName" onChange={(e) => setcustName(e.target.value)} placeholder="First name" />
                  </div>
                </div>
              </div>

              <div className='Email'>
                <input type="email" className="form-control custEmail" onChange={(e) => setcustEmail(e.target.value)} placeholder="Email" />
              </div>

              <div className='Phone-no'>
                <input type="number" className="form-control custPhone" onChange={(e) => setcustPhone(e.target.value)} placeholder="contact no" />
              </div>
              <div className='message'>
                <textarea type="text" className="form-control custMessage" onChange={(e) => setcustMessage(e.target.value)} placeholder='message' />
              </div>
              <button className='form-submit-button' onClick={submitForm} >Submit</button>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default ContactUs