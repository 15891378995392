import React from 'react';
import "../CSS/BookingForm.css";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Footer from "../Component/Footer";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';


const BookingForm = () => {
    return (
        <>
            {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

            <div className='BookingForm-banner'>
                {/* <NavBarComponent /> */}
                <div className='BookingForm-banner-text'>
                    <h1>Book Your Appointment Now</h1>
                </div>
            </div>

            {/* ////////////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Bookin-Form-Section-2'>
                <div className='container'>
                    <div className='Booking-Form-heading'>
                        <h2>Book Your Appointment Now</h2>
                        <hr></hr>
                    </div>
                    <div className='Booking-Form-Con'>
                        <div>
                            <Formik
                                initialValues={{ name: '', email: '', contact: '', date: '' }}
                                validate={values => {
                                    const errors = {};

                                    if (!values.name) {
                                        errors.name = 'Required';
                                    }
                                    if (!values.email) {
                                        errors.email = 'Required';
                                    } else if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                    ) {
                                        errors.email = 'Invalid email address';
                                    }
                                    if (!values.contact) {
                                        errors.contact = 'Required';
                                    }
                                    if (!values.date) {
                                        errors.date = 'Required';
                                    }
                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        alert(JSON.stringify(values, null, 2));
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='Form-Items'>
                                                    <label htmlFor="location">Enter name</label>
                                                    <Field type="name" name="name" placeholder="Enter name" required />
                                                    <ErrorMessage name="name" component="div" className='ErrorMessage'/>
                                                </div>
                                            </div>
                                            {/* <div className='col-md-6'>
                                                <div className='Form-Items'>
                                                    <label htmlFor="location">Last name</label>
                                                    <Field type="lastname" name="lastname" placeholder="Enter last name" />
                                                    <ErrorMessage name="lastname" component="div" className='ErrorMessage'/>
                                                </div>
                                            </div> */}
                                            <div className='col-md-6'>
                                                <div className='Form-Items'>
                                                    <label htmlFor="location">Enter Email</label>
                                                    <Field type="email" name="email" placeholder="Enter Email" />
                                                    <ErrorMessage name="email" component="div" className='ErrorMessage'/>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Form-Items'>
                                                    <label htmlFor="location">Enter Contact</label>
                                                    <Field type="contact" name="contact" placeholder="Enter Contact" />
                                                    <ErrorMessage name="contact" component="div" className='ErrorMessage'/>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='Form-Items'>
                                                    <label htmlFor="location">Appointment Date </label>
                                                    <Field type="date" name="date" />
                                                    <ErrorMessage name="date" component="div" className='ErrorMessage'/>
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className='Form-Items'>
                                                    <label htmlFor="location">Message</label>
                                                    <Field type="text" as="textarea" name="Message" placeholder="Message" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='Submit-btn'>
                                            <button type="submit" disabled={isSubmitting}>
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />



        </>
    )
}

export default BookingForm