import React, { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "../CSS/ServiceInfoPage.css";
import Footer from "../Component/Footer";
import HeadOfClinic from "../Images/Doctor Images/head-of-clinic.jpg";
import Servicesdrop from '../Component/Servicesdrop';


const ServiceInfoPage = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const search = useLocation();
    const Path = search.pathname;

    const EventFdata = Servicesdrop.find((e) => e.Slugs == Path);
    return (
        <>
            {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

            <div className='ServiceInfoPage-banner'>
                {/* <NavBarComponent /> */}
                <div className='ServiceInfoPage-banner-text'>
                    <h1>{EventFdata.Servicesdropname}</h1>
                </div>
            </div>

            {/* ////////////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='ServicesInfo-Section2'>
                <div className='container'>
                    <div className='SIS-Data'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='ServiesDetails-con'>
                                    <div className='ServiesInfoName'>
                                        <h2>
                                            {EventFdata.Servicesdropname}
                                        </h2>
                                    </div>
                                    <div className='ServicesInfoImage-con'>
                                        <div className='ServicesInfoImage'>
                                            <img src={EventFdata.ServicesImage} alt="" />
                                        </div>
                                    </div>

                                    <div className='ServicesInfoPara'>
                                        <p>
                                            {EventFdata.HeadDes01}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes02}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes03}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes04}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes05}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes06}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes07}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes08}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes09}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes10}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes11}
                                        </p>
                                        <p>
                                            {EventFdata.HeadDes12}
                                        </p>

                                    </div>
                                    <div className='Pointfirstsection'>
                                        <h2>{EventFdata.PointHeadTitle01}</h2>
                                        <h3>{EventFdata.PointHead01}</h3>
                                        <p>{EventFdata.Point01}</p>
                                        <p>{EventFdata.Point02}</p>
                                        <p>{EventFdata.Point03}</p>
                                        <p>{EventFdata.Point04}</p>
                                        <p>{EventFdata.Point05}</p>
                                        <p>{EventFdata.Point06}</p>
                                        <p>{EventFdata.Point07}</p>
                                        <p>{EventFdata.Point08}</p>
                                        <p>{EventFdata.Point09}</p>
                                        <p>{EventFdata.Point10}</p>
                                        <p>{EventFdata.Point11}</p>
                                    </div>
                                    <div className='Pointfirstsection'>
                                        <h3>{EventFdata.PointHead001}</h3>
                                        <p>{EventFdata.Point01}</p>
                                        <p>{EventFdata.Point02}</p>
                                        <p>{EventFdata.Point03}</p>
                                        <p>{EventFdata.Point04}</p>
                                        <p>{EventFdata.Point05}</p>
                                        <p>{EventFdata.Point06}</p>
                                        <p>{EventFdata.Point07}</p>
                                        <p>{EventFdata.Point08}</p>
                                        <p>{EventFdata.Point09}</p>
                                        <p>{EventFdata.Point10}</p>
                                        <p>{EventFdata.Point11}</p>
                                    </div>
                                    <div className='Pointfirstsection'>
                                        <h3>{EventFdata.Ques01}</h3>
                                        <p>{EventFdata.Ans001}</p>
                                        <p>{EventFdata.Ans001}</p>
                                    </div>
                                    <div className='Pointfirstsection'>
                                        <p>{EventFdata.EndDes01}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                {/* <div className='SInfo-DocotrDetails'>
                                    <div className='ServicesInfo-head'>
                                        <h4>The Heart Of Clinic</h4>
                                        <hr></hr>
                                    </div>
                                    <div className='ServicesInfo-Doctor'>
                                        <div className='DoctorImage'>
                                            <img src={HeadOfClinic} alt="" />
                                        </div>
                                        <div className='DoctorInfo'>
                                            <div className='SInac'>
                                                <h4>Dr. Jonathan Barnes</h4>
                                                <p>Chief Medical Officer, Founder</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='SInfo-TimeTabel-con'>
                                    <div className='SInfo_TimeTabel'>
                                        <h4>Working Time</h4>
                                        <h2>Daily	-	9:00 AM - 10:00 PM</h2>
                                        <h3>24 * 7 Services</h3>
                                        <h6>Emergency Service</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Footer />

        </>
    )
}

export default ServiceInfoPage