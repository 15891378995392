import Image01 from "../Images/Maternity Hospital/Brest Lump.webp";
import Image02 from "../Images/OrthoPedic Surgeon/Bone Plating And Nailing.jpg";

const ImageGalleryData = [
    {
        id: 1,
        Category:"Hospital",
        EImage: Image01,
    },
    {
        id: 2,
        Category:"Staff",
        EImage: Image02,
    },
    {
        id: 3,
        Category:"Rooms",
        EImage: Image02,
    },
    {
        id: 4,
        Category:"Doctor",
        EImage: Image02,
    },
    {
        id: 5,
        Category:"Festival",
        EImage: Image02,
    },
    {
        id: 6,
        Category:"Room",
        EImage: Image02,
    },
];

export default ImageGalleryData;
