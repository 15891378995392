import React, { useState, useLayoutEffect } from 'react';
import "../CSS/ImageGallery.css";
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Component/ImageGalleryData';
import Footer from "../Component/Footer";
import Bounce from 'react-reveal/Bounce';
import NavBar from '../Component/NavBar';

const ImageGallery = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>

            {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

            <div className='ImageGallery-banner'>
                {/* <NavBarComponent /> */}
                <div className='ImageGallery-banner-text'>
                    <h1>Image Gallery</h1>
                </div>
            </div>

            {/* ////////////////////////////////////// section 2 ////////////////////////////////// */}

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />


        </>
    )
}

export default ImageGallery