import React, { useLayoutEffect } from 'react';
import "../CSS/DoctorPage.css";
import { Link } from 'react-router-dom';
import Footer from "../Component/Footer";

import ArchanaPatil from "../Images/Doctor Images/Archana Patil.jpeg";
import ChetanPatil from "../Images/Doctor Images/Chetan Patil.jpeg";
import PallaviTari from "../Images/Doctor Images/Pallavi Tari.jpeg";
import PradeepSingh from "../Images/Doctor Images/Pradeep Singh.jpeg";
import DevendraBhole from "../Images/Doctor Images/Devendra Bhole.jpg";
import AdvaitJathar from "../Images/Doctor Images/Dr. Advait Jathar.jpeg";
import RohanJaju from "../Images/Doctor Images/Dr. Rohan Jaju.jpeg";
import JitendraMornakar from "../Images/Doctor Images/Dr. Jitendra Mornakar.jpeg";

import { IoCall } from 'react-icons/io5';
import { HiMail } from 'react-icons/hi';
const DoctorPage = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    return (
        <>
            {/* ////////////////////////////////////// section 1 ////////////////////////////////// */}

            <div className='Doctor-banner'>
                {/* <NavBarComponent /> */}
                <div className='Doctor-banner-text'>
                    <h1>Doctors</h1>
                </div>
            </div>

            {/* ////////////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Dorctor-Section-2'>

                <div className='container'>
                    <div className='row'>


                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={ChetanPatil} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Chetan Patil</h3>
                                    <span>Gynaecologist & Maternity care </span>
                                    <p>M.B.B.S., DGO, DNB(MUM)</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={PradeepSingh} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Pradeep Singh</h3>
                                    <span>General Medicine</span>
                                    <p>M.B.B.S., DNB, Medicine</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={PallaviTari} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Pallavi Tari</h3>
                                    <span>Paediatric doctor</span>
                                    <p>M.B.B.S., DCH, PGPN</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={ArchanaPatil} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Archana Patil</h3>
                                    <span>Dermatologist doctor</span>
                                    <p>M.B.B.S., DNB, Skin (Mum)</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={DevendraBhole} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Devendra Bhole</h3>
                                    <span>Radiologist Doctor</span>
                                    <p>M.B.B.S. DMRE</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={AdvaitJathar} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Advait Jathar</h3>
                                    <span>Surgeon Doctor</span>
                                    <p>MS Gen Surgeon</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={RohanJaju} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Rohan Jaju</h3>
                                    <span>Urologist Doctor</span>
                                    <p>MBBS, MS</p>
                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div class="card">
                                <div class="card_profile_img">
                                    <img src={JitendraMornakar} alt="" />
                                </div>

                                <div class="card_content">
                                    <h3>Dr. Jitendra Mornakar</h3>
                                    <span>Surgeon Doctor</span>
                                    <p>MBBS DNB GENERAL SURGERY, FIAGES.</p>

                                    <div className='Doctor-Card-Con'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Call_Con'>
                                                    <div className='Doctor-Card-Call_Con-Btn'>
                                                        <a>
                                                            <IoCall />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='Doctor-Card-Mail_Con'>
                                                    <div className='Doctor-Card-Mail_Con-Btn'>
                                                        <a>
                                                            <HiMail />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Doctor-Booking'>
                                        <Link to="/BookingForm">
                                            <button>
                                                Book An Appointment
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DoctorPage