import men from "../Images/Icons/User-Profile-PNG.png";
import women from "../Images/Icons/Female Demo_1.jpg";
    const TestimonialData = [
        {
            id: 1,
            Name: "Prasad Sonule",
            Discription:"I had a great experience at this hospital. The staff was very caring and attentive to my needs. The doctors explained everything thoroughly, and I felt well taken care of.",
            Image: women,
        },
        {
            id: 2,
            Name: "Ankush Ankush Jambhavdekar",
            Discription:"The cleanliness and hygiene at this hospital are top-notch. I felt safe and comfortable during my stay, and the medical staff was highly skilled.",
            Image: men,
        },
        {
            id: 3,
            Name: "Anil Chavan",
            Discription:"I can't thank the hospital enough for the exceptional care I received. The nurses were so kind, and the doctors were very knowledgeable.",
            Image: women,
        },  
        {
            id: 4,
            Name: "Gawali Distributors",
            Discription:"I was admitted to this hospital for surgery, and the entire process was smooth. The surgeons were skilled, and I had a quick recovery.",
            Image: women,
        },  
        {
            id: 5,
            Name: "Mahendra Phate",
            Discription:"The hospital has state-of-the-art equipment, and the diagnostic facilities are excellent. I received accurate and prompt test results, which was crucial for my treatment.",
            Image: men,
        },  
        {
            id: 6,
            Name: "Chandrabhan Wagh",
            Discription:"The hospital's emergency department was quick to respond when I had a health crisis. The medical team was efficient and saved my life.",
            Image: men,
        },  
        {
            id: 7,
            Name: "Jitendar Kumar",
            Discription:"I brought my child here for treatment, and the pediatric department was fantastic. They made my child feel at ease, and I was very happy with the care.",
            Image: men,
        },  
        {
            id: 8,
            Name: "Avinash Gursale",
            Discription:"The hospital's cardiology department is exceptional. The doctors and nurses provided excellent care for my heart condition, and I am grateful for their expertise.",
            Image: men,
        },  
        {
            id: 9,
            Name: "Nandini Gupta",
            Discription:"I was impressed with the hospital's rehabilitation services. The physiotherapists worked with me diligently to help me regain my mobility.",
            Image: men,
        },  
        {
            id: 10,
            Name: "Deepak Gupta",
            Discription:"The hospital's support staff, including the administrative team and housekeeping, were friendly and made my stay as pleasant as possible.",
            Image: men,
        },  
        {
            id: 11,
            Name: "Ravi Gaikwad",
            Discription:"I received cancer treatment at this hospital, and the oncology team was compassionate and provided great emotional support during a challenging time.",
            Image: men,
        },  
        {
            id: 12,
            Name: "Mahesh Pawar",
            Discription:"I had a smooth experience with the hospital's billing and insurance department. They helped me navigate the financial aspects of my treatment efficiently.",
            Image: men,
        },  
        {
            id: 13,
            Name: "Pandurang Kolekar",
            Discription:"The hospital's cafeteria offers a variety of tasty and hygienic food options. It was a relief to have good meals during my hospital stay.",
            Image: men,
        },  
        {
            id: 14,
            Name: "Mukul Wagh",
            Discription:"The hospital's post-operative care was excellent. The nurses and doctors were attentive, and they ensured my recovery was on the right track.",
            Image: men,
        },  
        {
            id: 15,
            Name: "Sudam More",
            Discription:"I would highly recommend this hospital to anyone in need of medical care. The level of expertise and the patient-centered approach are truly commendable.",
            Image: men,
        },  
    ];
    export default TestimonialData;
