// import logo from './logo.svg';
import './App.css';
import NavBar from './Component/NavBar';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './Pages/HomePage';
import ServicesPage from './Pages/ServicesPage';
import ContactUs from './Pages/ContactUs';
import AboutUs from './Pages/AboutUs';
import ServicesInfo from './Pages/ServicesInfo';
import Testimonial from './Pages/Testimonial';
import ImageGallery from './Pages/ImageGaller';
import VideGallerypage from "./Pages/VideoGalleryPage";
import DoctorPage from './Pages/DoctorPage';
import BookingForm from './Component/BookingForm';
import SpecialitiesPage from './Pages/SpecialitiesPage';
import ServiceInfoPage from './Pages/ServiceInfoPage';
import Servicesdrop from './Component/Servicesdrop';
import SpecialitiesdropData from './Component/SpecialitiesdropData';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path='/' element={<HomePage />} />
          {/* <Route path='/Services' element={<ServicesPage />} /> */}
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/Doctors' element={<DoctorPage />} />
          <Route path='/Testimonial' element={<Testimonial />} />
          <Route path='/ImageGallery' element={<ImageGallery />} />
          <Route path='/VideoGallery' element={<VideGallerypage />} />
          <Route path='/AboutUs' element={<AboutUs />} />
          <Route path='/ServiceInfo' element={<ServicesInfo />} />
          <Route path='/BookingForm' element={<BookingForm />} />
          {/* <Route path='/SpecialitiesInfo' element={<SpecialitiesPage />} /> */}
          {/* <Route path='/ServiceInfoPage' element={<ServiceInfoPage />} /> */}

          {
            Servicesdrop.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.Slugs}
                    element={<ServiceInfoPage />} />
                </>
              )
            })
          }

          {
            SpecialitiesdropData.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.Slugs}
                    element={<SpecialitiesPage />} />
                </>
              )
            })
          }

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
